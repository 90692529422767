import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Table from '../../../components/Table/Table';
import { returnRows, returnRowsCSV } from './common';
import Input from '../../../components/Input/Input';

const TableKeywords = ({ queryTop100 = [], dayStart = '', dayEnd = '' }) => {
  const firstCol = 'query';
  const firstColPl = 'Fraza';
  const [searchText, setSearchText] = useState('');

  const onChangeHandler = (event) => {
    setSearchText(event.target.value)
  }
  if (searchText) {
    queryTop100 = queryTop100.filter(el => el.query && el.query.includes(searchText))
  }

  const rows = returnRows(queryTop100, firstCol);
  const _rows = JSON.parse(JSON.stringify(rows));
  const rowsCSV = returnRowsCSV(_rows);

  return (
    <div name="href6" className="px-4 pb-5 d-print-none">
      <div className="tooltip2">
        <div className="position-relative">
          <span className="tooltiptext">Frazy kluczowe, które w badanym okresie zanotowały co najmniej jedno kliknięcie, w porównaniu z poprzednim okresem. Dane pochodzą z Google Search Console.</span>
        </div>
        <h5 className="mb-5 text-center">Wszystkie frazy generujące ruch</h5>
      </div>
      <Input {...{ type: 'text', placeholder: 'filtruj...', onChange: onChangeHandler }} />
      <Table rows={rows} firstColPl={firstColPl} searchText={searchText}/>
      <div className="text-right mt-2">
        <CSVLink data={rowsCSV} filename={`Raport SEO - CubeGroup - Wszystkie frazy generujące ruch od ${dayStart} do ${dayEnd}.csv`} separator=";">
          Pobierz dane w formacie csv
        </CSVLink>
      </div>
    </div>
  );
};

TableKeywords.propTypes = {
  query: PropTypes.array,
  ctr: PropTypes.func,
  toComma: PropTypes.func,
};

export default TableKeywords;
