import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import LineChart from '../../../components/LineChart/LineChart';
import Checkbox from '../../../components/Checkbox/Checkbox';

class TableKeywordsByDays extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      keywordsCheck: [],
      queryDays: {},
    };
  }

  componentDidMount() {
    // Ustawia poczatkową wartość dla wykresu - Słowa kluczowe wg dni.
    const { queryDays = {} } = this.props;
    const arrValues = Object.keys(queryDays.values).map((index) => Object.keys(queryDays.values[index])[0]);
    let keywordsCheck = [];
    for (let i = 0; i < arrValues.length; i++) {
      const random = Math.floor(Math.random() * arrValues.length);
      const val = arrValues[random];
      if (!keywordsCheck.find((item) => item === val)) {
        keywordsCheck.push(val);
      }
      if (keywordsCheck.length === 5) {
        break;
      }
    }
    this.setState(
      {
        queryDays,
        keywordsCheck,
      },
      () => this.prepareData()
    );
  }

  handleChange(event) {
    let { keywordsCheck } = this.state;
    const { name } = event.target;

    if (this.state.keywordsCheck.indexOf(name) > -1) {
      keywordsCheck = keywordsCheck.filter((el) => el !== name);
    } else {
      keywordsCheck.push(name);
    }

    this.setState({ keywordsCheck }, () => this.prepareData());
  }

  prepareData() {
    const { queryDays = {} } = this.state;
    const keywordsChart = {
      labels: queryDays.headers,
      values: [],
      label: [],
    };

    this.state.keywordsCheck.forEach((el) => {
      keywordsChart.values.push(Object.values(queryDays.values.find((el2) => Object.keys(el2)[0] === el)[el]));
      keywordsChart.label.push(el);
    });

    // dane do pobrania w formacie csv
    //
    const csvData =
      queryDays.values &&
      queryDays.values.map((row) => {
        const line = queryDays.headers && queryDays.headers.map((el) => Object.values(row)[0][el]);
        line.unshift(Object.keys(row)[0]);
        return line;
      });
    const csvHeaders = Object.assign([], queryDays.headers);
    csvHeaders.unshift('Fraza');
    csvData.unshift(csvHeaders);

    this.setState({
      keywordsChart,
      csvData,
    });
  }

  renderTableHeader() {
    const { queryDays = {} } = this.state;
    return (
      <tr>
        <th>Fraza</th>
        <th />
        {queryDays.headers && queryDays.headers.length
          ? queryDays.headers.map((el, key) => (
              <th key={key}>
                <div className="position-relative">
                  <span>{moment(el).format('DD MMM')}</span>
                </div>
              </th>
            ))
          : null}
      </tr>
    );
  }

  renderTableRows() {
    const { queryDays = {} } = this.state;
    return (
      queryDays.values &&
      queryDays.values.map((row, key) => (
        <tr key={key}>
          <td>{Object.keys(row)[0]}</td>
          <td>
            <Checkbox
              name={Object.keys(row)[0]}
              checked={this.state.keywordsCheck.indexOf(Object.keys(row)[0]) > -1}
              onChange={(e) => this.handleChange(e)}
              disabled={this.state.keywordsCheck.length >= 5 && this.state.keywordsCheck.indexOf(Object.keys(row)[0]) === -1}
            />
          </td>
          {queryDays.headers && queryDays.headers.map((el, key2) => <td key={key2}>{Object.values(row)[0][el]}</td>)}
        </tr>
      ))
    );
  }

  renderChart() {
    const { keywordsChart = {} } = this.state;
    return keywordsChart ? <LineChart labels={keywordsChart.labels} values={keywordsChart} reverse getColor={this.props.getColor} /> : '';
  }

  renderCSVLink() {
    const { csvData = [] } = this.state;
    const { dayStart = '', dayEnd = '' } = this.props;
    return (
      <CSVLink data={csvData} filename={`Raport SEO - CubeGroup - Dzienne pozycje monitorowanych fraz od ${dayStart} do ${dayEnd}.csv`} separator=";" className="d-print-none">
        Pobierz dane w formacie csv
      </CSVLink>
    );
  }

  render() {
    return (
      <div name="href5" className="px-4 py-5">
        <div className="d-print-none">
          <div className="tooltip2">
            <div className="position-relative">
              <span className="tooltiptext">
                Wykres średnich pozycji dziennych maksymalne 5 wybranych fraz spośród monitorowanych w tabeli poniżej. Na wykresie wyświetlane są dane z maksymalnie 31 dni wstecz od ustawionej daty
                końcowej analizowanego okresu. Brakujące znaczniki wskazują na brak wyszukiwań frazy danego dnia, a więc i brak pomiaru pozycji. Dane zaokrąglone, pochodzą z Google Search Console.
              </span>
            </div>
            <h5 className="mb-5 text-center">Wykres dziennych pozycji monitorowanych fraz</h5>
          </div>
          {this.renderChart()}
        </div>
        <div className="pt-5 print-page-break-before" name="href2">
          <div className="tooltip2">
            <div className="position-relative">
              <span className="tooltiptext">
                Średnie pozycje dzienne wszystkich monitorowanych fraz kluczowych. W tabeli wyświetlane są dane z maksymalnie 31 dni wstecz od ustawionej daty końcowej analizowanego okresu. Wybór fraz
                do wykresu pozycji poprzez zaznaczenie maksymalnie 5 fraz. Puste miejsca oznaczają brak wyszukiwań frazy danego dnia, a więc i brak pomiaru pozycji. Dane zaokrąglone, pochodzą z Google
                Search Console.
              </span>
            </div>
            <h5 className="mb-5 text-center">Dzienne pozycje monitorowanych fraz</h5>
          </div>
          <div className="table-responsive-lg with-padd">
            <table className="table query-days">
              <tbody>
                {this.renderTableHeader()}
                {this.renderTableRows()}
              </tbody>
            </table>
          </div>
          <div className="text-right mt-2">{this.renderCSVLink()}</div>
        </div>
      </div>
    );
  }
}

TableKeywordsByDays.propTypes = {
  queryDays: PropTypes.object,
};

export default TableKeywordsByDays;
