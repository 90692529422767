import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';

const API_SERVER = process.env.REACT_APP_API_SERVER;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: '',
    };
    props.onLogin(null);
  }

  onSubmit(e) {
    e.preventDefault();
    const form = new FormData(e.target);
    const formData = {};
    form.forEach((value, key) => {
      formData[key] = value;
    });
    fetch(e.target.action, {
      method: e.target.method,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          this.setState({ error: res.error });
        } else {
          const { user, redirectTo } = res;
          this.props.onLogin(user);
          window.location.href = redirectTo;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="container min-vh-100">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-sm-10 col-lg-5">
            {this.state.error.length > 0 && (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}
            <Form method="post" action={`${API_SERVER || ''}/login`} onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="user-email">E-mail</Label>
                <Input type="email" name="email" id="user-email" placeholder="E-mail" />
              </FormGroup>
              <FormGroup>
                <Label for="user-password">Hasło</Label>
                <Input type="password" name="password" id="user-password" placeholder="Hasło" />
              </FormGroup>
              <Input type="submit" value="Zaloguj" />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
