import React from 'react';
import NumberFormat from './NumberFormat';

export const renderNumber = (value, decimal, sign) => {
  if (value === 0) { decimal = 0; }
  return <span><NumberFormat value={value} decimal={decimal}/>{sign}</span>;
};

export const renderDifference = (value, decimal, sign) => {
  if (isNaN(value)) {
    value = 0;
  }
  const color = value > 0 ? 'text-green' : value < 0 ? 'text-red' : '';
  const plus = value > 0 ? '+' : '';
  return <span className={`font9 ${color}`}>{plus}{renderNumber(value, decimal, sign)}</span>;
};
