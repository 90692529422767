import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import moment from 'moment';
import Error from '../../../components/Error/Error';
import fetchReport from '../fetchReport';
import CompetitionClientsSummary from './CompetitionClientsSummary';
import shouldReload from '../shouldReload';

class CompetitionClientsChart extends Component {
  constructor(props) {
    super(props);
    const { dayStart, dayEnd } = this.props;
    this.state = {
      loader: true,
      dayStart,
      dayEnd,
      data: {},
      chartData: {},
      summaryData: {},
      actualMonthErr: false,
    };
    this.chartConfig = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
      },
      tooltips: {
        intersect: false,
        mode: 'index',
        // bodySpacing: 3,
        yAlign: 'center',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgba(155,155,155, 1)',
        borderWidth: 1,
        bodyFontColor: '#000',
        titleFontColor: '#000',
        titleMarginBottom: 4,
        callbacks: {
          label(tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString('fr-FR')}`;
          },
          labelColor: (tooltipItem, data) => {
            const item = data.config.data.datasets[tooltipItem.datasetIndex];
            return {
              borderColor: item.borderColor,
              backgroundColor: item.dashed ? '#FFF' : item.borderColor,
            };
          },
        },
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
          },
        ],
        yAxes: [
          {
            position: 'left',
            id: 'y-axis-1',
            scaleLabel: {
              display: true,
              labelString: 'Widoczność',
            },
            ticks: {
              callback(value) {
                return Number(value).toLocaleString('fr-FR');
              },
            },
          },
          {
            display: false,
            position: 'right',
            id: 'y-axis-2',
            scaleLabel: {
              display: true,
              labelString: 'Ruch',
            },
            ticks: {
              callback(value) {
                return Number(value).toLocaleString('fr-FR');
              },
            },
          },
        ],
      },
    };
    this.values = ['TOP 3', 'TOP 10', 'TOP 20', 'RUCH'];
  }

  prepareSummaryData(data) {
    const dataClone = { ...data, ...{} };
    const summary = {
      domains: [],
      'TOP 3': [],
      'TOP 10': [],
      'TOP 20': [],
      RUCH: [],
    };
    // dataClone[domain] kluczami są daty posortowane od najstarszej do najnowszej
    for (const domain in dataClone) {
      summary.domains = [...summary.domains, ...[domain]];
      const lastTwoIndexes = Object.values(dataClone[domain]).slice(-2);
      const top3Values = lastTwoIndexes.map((item) => item['TOP 3']);
      const top10Values = lastTwoIndexes.map((item) => item['TOP 10']);
      const top20Values = lastTwoIndexes.map((item) => item['TOP 20']);
      const trafficValues = lastTwoIndexes.map((item) => item.RUCH);
      // [0] poprzedni miesiąc
      // [1] aktualny miesiąc
      summary['TOP 3'] = [...summary['TOP 3'], ...[top3Values[1], ...[top3Values[1] - top3Values[0]]]];
      summary['TOP 10'] = [...summary['TOP 10'], ...[top10Values[1], ...[top10Values[1] - top10Values[0]]]];
      summary['TOP 20'] = [...summary['TOP 20'], ...[top20Values[1], ...[top20Values[1] - top20Values[0]]]];
      summary.RUCH = [...summary.RUCH, ...[trafficValues[1], ...[trafficValues[1] - trafficValues[0]]]];
    }
    return summary;
  }

  prepareChartData(data) {
    const { website: clientWebsite } = this.props.client;
    const website = clientWebsite.indexOf(' (UD)') > -1 ? clientWebsite.replace(' (UD)', '') : clientWebsite.replace('www.', '');
    const datasetConf = {
      data: [],
      fill: false,
      radius: 3.5,
      pointBorderColor: 'rgba(0, 0, 0, 0)',
      pointBackgroundColor: 'rgba(0, 0, 0, 0)',
      pointHoverBackgroundColor: 'white',
      pointHoverBorderColor: 'black',
      borderWidth: 2,
    };
    const chartData = {
      labels: Object.keys(data[website]).map((item) => item.slice(0, -3)),
      datasets: [],
    };
    if (data) {
      Object.keys(data).forEach((domain, domainIndex) => {
        const values = Object.values(data[domain]);
        this.values.forEach((value, valueIndex) => {
          const color = this.props.getColor(domainIndex, valueIndex, false);
          const singleDataset = {
            ...datasetConf,
            ...{
              label: `${domain} ${value}`,
              borderColor: color,
              data: values.map((item) => item[value]),
              // hidden: ['TOP 20', 'RUCH'].includes(value) || domainIndex > 2,
              hidden: ['TOP 3', 'TOP 20', 'RUCH'].includes(value),
              yAxisID: `y-axis-${value === 'RUCH' ? 2 : 1}`,
              borderDash: [valueIndex > 1 ? 5 : 0],
              dashed: valueIndex > 1,
            },
          };
          chartData.datasets = [...chartData.datasets, singleDataset];
        });
      });
    }
    return chartData;
  }

  fetchData(reloadActualMonthData = false) {
    const { client, actualMonthData, setActualMonthData } = this.props;
    const { dayStart, dayEnd } = this.state;
    if (client && client._id) {
      this.setState({ loader: true }, () => {
        fetchReport(client, dayStart, dayEnd, undefined, undefined, 'get-clients-competition-data')
          .then((res) => {
            this.setState(
              {
                loader: false,
                data: res,
                chartData: this.prepareChartData(res),
                summaryData: this.prepareSummaryData(res),
              },
              () => {
                if (!actualMonthData || reloadActualMonthData) {
                  fetchReport(client, undefined, undefined, undefined, undefined, 'get-clients-competition-data', { actualMonth: true })
                    .then((actualMonthRes) => {
                      if (actualMonthRes) {
                        setActualMonthData(actualMonthRes);
                        if (dayEnd.format('YYYYMM') === moment().format('YYYYMM')) {
                          const monthsData = this.state.data;
                          for (const domain in actualMonthRes) {
                            monthsData[domain] = { ...monthsData[domain], ...actualMonthRes[domain] };
                          }
                          this.setState({
                            data: monthsData,
                            chartData: this.prepareChartData(monthsData),
                            summaryData: this.prepareSummaryData(monthsData),
                          });
                        }
                      }
                    })
                    .catch(() => {
                      this.setState({
                        actualMonthErr: true,
                      });
                    });
                }
              }
            );
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              info: (
                <div style={{ textAlign: 'center' }}>
                  <h4>Wykres miesięcznej widoczności i szacowanego ruchu w porównaniu z konkurencją w ostatnim roku</h4>
                  <p style={{ color: 'red' }}>aktualnie niedostępny</p>
                  <hr />
                </div>
              ),
              loader: false,
            });
          });
      });
    }
  }

  componentDidMount() {
    this.fetchData(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const newData = shouldReload(prevProps, prevState, this.props);
    if (newData) {
      this.setState(newData, () => {
        this.fetchData(prevProps.client._id !== this.props.client._id);
      });
    }
  }

  render() {
    const { info, chartData, loader, summaryData, data, actualMonthErr } = this.state;
    const { datasets } = chartData;
    return loader ? (
      <div className="lds-dual-ring" id="spinner" />
    ) : Object.keys(chartData).length > 0 ? (
      <div name="href4">
        <CompetitionClientsSummary summary={summaryData} addThousands={this.props.addThousands} getDiff={this.props.getDiff} actualMonthErr={actualMonthErr} dayEnd={this.props.dayEnd} />
        <div className="px-4 py-5 print-page-break-before">
          <div className="tooltip2">
            <div className="position-relative">
              <span className="tooltiptext">
                Wykres danych miesięcznych dla ostatnich 12 miesięcy licząc od najbardziej aktualnego miesiąca z wybranego zakresu czasu raportu. Dane pochodzą z narzędzia SEMSTORM.
                <br />
                <br />
                Widoczność – szacunkowa miesięczna łączna liczba unikalnych fraz kluczowych w danym zakresie pozycji.
                <br />
                <br />
                Ruch – szacowany miesięczny ruch organiczny ze wszystkich fraz kluczowych, uzależniony od pozycji danej frazy i szacunkowego CTR odpowiadającemu miejscu w rankingu.
              </span>
            </div>
            <h5 className="mb-5 text-center">Wykres miesięcznej widoczności i szacowanego ruchu w porównaniu z konkurencją w ostatnim roku</h5>
          </div>
          <div className="chart-js position-relative" style={{ height: '310px' }}>
            <Line data={chartData} options={this.chartConfig} height={310} />
          </div>
        </div>
        <div className="px-4">
          <div className="table-responsive">
            <table className="table-legend-competition table mb-0 text-left">
              <thead>
                <tr>
                  {this.values.map((val) => (
                    <th key={val}>{val}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).map((domain, domainIndex) => (
                  <tr key={domain}>
                    {this.values.map((val, valIndex) => {
                      const dataset = datasets.filter((item) => item.label === `${domain} ${val}`)[0];
                      return (
                        <td key={val} className="pb-05">
                          <span
                            className={`legend-point ${dataset && dataset.hidden ? 'stroke' : ''}`}
                            onClick={() => {
                              dataset.hidden = !dataset.hidden;
                              if (dataset.label.indexOf('RUCH') > -1) {
                                this.chartConfig.scales.yAxes[1].display = !dataset.hidden;
                              }
                              this.setState(chartData);
                            }}
                          >
                            <i className="legend-ico" style={this.props.getColor(domainIndex, valIndex)} />
                            <span className="stroke-line">{domain}</span>
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : info ? (
      <Error info={info.toString().indexOf('Throttle prevention') > -1 ? 'Dane aktualnie niedostępne' : info} />
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  actualMonthData: state.actualMonthData,
});

const mapDispatchToProps = {
  setActualMonthData: (actualMonthData) => ({
    type: 'SET_ACTUAL_MONTH_DATA',
    actualMonthData,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionClientsChart);
