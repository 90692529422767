import store from '../../../src/containers/App/store';
const moment = require('moment');
const API_SERVER = process.env.REACT_APP_API_SERVER || '../..';

const setReqCounter = (reqCounter) =>
	store.dispatch({
		type: 'SET_REQ_COUNTER',
		reqCounter
	});

let reqCounter = 0;
export default (client, dayStart, dayEnd, prevStart, prevEnd, api, rest = {}) =>
	new Promise((resolve, reject) => {
		let params = Object.assign(
			{},
			{
				clientId: client._id,
				alias: client.alias,
				dayStart: dayStart ? dayStart.format('YYYY-MM-DD') : undefined,
				dayEnd: dayEnd ? dayEnd.format('YYYY-MM-DD') : undefined,
				prevStart: prevStart ? prevStart.format('YYYY-MM-DD') : undefined,
				prevEnd: prevEnd ? prevEnd.format('YYYY-MM-DD') : undefined,
				diff: dayStart && dayEnd ? moment(dayEnd).add(1, 'days').diff(dayStart, 'days') : undefined
			},
			rest
		);
		// params.dayStart = '2021-02-01';
		// params.dayEnd = '2021-02-28';
		// params.prevStart = '2021-01-01';
		// params.prevEnd = '2021-01-31';
		// params.alias = 'Provident';
		// params.clientId = '5bd59b6635a3ed0027a5a815';
		// params.alias = 'dsinvimedpltest';
		// params.clientId = '5c9c8ab97b2ea8001977edd8';
		setReqCounter(++reqCounter);
		fetch(`${API_SERVER}/api/${api}`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(params)
		})
			.then((res) => (res.status !== 200 ? [ console.error(res && res.statusText), reject() ] : res.json()))
			.then((response) => {
				if (response.error) {
					console.error('Error:', response.error);
					return reject(response.error);
				}
				resolve(response.success || response);
			})
			.catch((error) => {
				console.error('Error:', error);
				reject(error);
			})
			.finally(() => {
				setReqCounter(--reqCounter);
			});
	});
