import React from 'react';

export default ({
                  addThousands,
                  gr1,
                  gr2,
                  gr3,
                  gr4,
                  prevGr1,
                  prevGr2,
                  prevGr3,
                  prevGr4,
                  getDiff,
                  percentageDifference,
                }) => {
  return (
    <div className="summary-section px-4 py-5 print-page-break-before">
      <div className="tooltip2">
        <div className="position-relative">
          <span className="tooltiptext">
            Średnia liczba fraz kluczowych na pozycjach w określonych przedziałach w analizowanym okresie. Statystyki przeliczane na bazie danych z Google Search Console.
          </span>
        </div>
        <h5 className="mb-5 text-center">Średnia dzienna widoczność fraz</h5>
      </div>
      <div className="table-responsive-md">
        <table className="table-legend-competition table mb-0 summary-table">
          <thead>
            <tr>
              <th className="tooltip2">
                <div className="position-relative">
                  <span className="tooltiptext">Średnia liczba fraz kluczowych w analizowanym okresie, dla których witryna znajduje się w przedziale od 1 do 3 miejsca. Dane zaokrąglone.</span>
                </div>
                średnia pozycja 1-3 <span className="quotation-mark"></span>
              </th>
              <th className="tooltip2">
                <div className="position-relative">
                  <span className="tooltiptext">Średnia liczba fraz kluczowych w analizowanym okresie, dla których witryna znajduje się w przedziale od 4 do 10 miejsca. Dane zaokrąglone.</span>
                </div>
                średnia pozycja 4-10 <span className="quotation-mark"></span>
              </th>
              <th className="tooltip2">
                <div className="position-relative">
                  <span className="tooltiptext">Średnia liczba fraz kluczowych w analizowanym okresie, dla których witryna znajduje się w przedziale od 11 do 20 miejsca. Dane zaokrąglone.</span>
                </div>
                średnia pozycja 11-20 <span className="quotation-mark"></span>
              </th>
              <th className="tooltip2">
                <div className="position-relative">
                  <span className="tooltiptext">Średnia liczba fraz kluczowych w analizowanym okresie, dla których witryna znajduje się dalej niż na 20 miejscu. Dane zaokrąglone.</span>
                </div>
                średnia pozycja 21+ <span className="quotation-mark"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="position-relative">
                  <span className="sum">{addThousands(Math.ceil(gr1))}</span>
                  <span className="diff">
                    <br />
                    <br />
                    {getDiff(gr1 - prevGr1, '', false, false)}
                    <br />
                    {getDiff(percentageDifference(prevGr1, gr1).slice(0, -1), '%', false, true)}
									</span>
                </span>
              </td>
              <td>
                <span className="position-relative">
                  <span className="sum">{addThousands(Math.ceil(gr2))}</span>
                  <span className="diff">
                    <br />
                    <br />
                    {getDiff(gr2 - prevGr2, '', false, false)}
                    <br />
                    {getDiff(percentageDifference(prevGr2, gr2).slice(0, -1), '%', false, true)}
									</span>
                </span>
              </td>
              <td>
                <span className="position-relative">
                  <span className="sum">{addThousands(Math.ceil(gr3))}</span>
                  <span className="diff">
                    <br />
                    <br />
                    {getDiff(gr3 - prevGr3, '', false, false)}
                    <br />
                    {getDiff(percentageDifference(prevGr3, gr3).slice(0, -1), '%', false, true)}
									</span>
                </span>
              </td>
              <td>
                <span className="position-relative">
                  <span className="sum">{addThousands(Math.ceil(gr4))}</span>
                  <span className="diff">
                    <br />
                    <br />
                    {getDiff(gr4 - prevGr4, '', false, false)}
                    <br />
                    {getDiff(percentageDifference(prevGr4, gr4).slice(0, -1), '%', false, true)}
									</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
