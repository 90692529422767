import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoginConnect from '../../views/User/LoginConnect';
import Sheet from '../../views/Sheet/Sheet';
import Error403 from '../../components/Errors/Error403';
import Error404 from '../../components/Errors/Error404';
import LogoutView from "../../components/Logout/LogoutView";
import store from './store';

sessionService.initSessionService(store);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Header />
        <Router>
          <Route render={({ location }) => (
            <Switch location={location}>
              <Route exact path="/" component={LoginConnect} />
              <Route exact path="/403" component={Error403} />
              <Route exact path="/raport/:hash" component={Sheet} />
              <Route exact path="/raport" component={Sheet} />
              <Route exact path="/login" component={LoginConnect} />
              <Route exact path="/logout" component={LogoutView} />
              <Route component={Error404} />
            </Switch>
          )}
          />
        </Router>
      </Provider>
    );
  }
}

export default App;
