import React from 'react';
import { connect } from 'react-redux';

const Sheet = (props) => {
  return (
    props.user
    ?
      <a
        href='/logout'
        className='d-print-none'
        style={{margin: 10}}
      >Wyloguj</a>
    : null
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Sheet);
