import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const LineChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: [],
  };

  props.values.forEach((value, key) => {
    data.datasets.push({
      label: props.values[key].label,
      hidden: value.hidden || false,
      fill: false,
      radius: 3.5,
      backgroundColor: 'transparent',
      lineTension: 0.1,
      borderColor: props.getColor(key, 0, false),
      borderDash: [key > 1 ? 5 : 0],
      pointBorderColor: 'rgba(0, 0, 0, 0)',
      pointBackgroundColor: 'rgba(0, 0, 0, 0)',
      pointHoverBackgroundColor: 'white',
      pointHoverBorderColor: 'black',
      borderWidth: 2,
      data: value.values[0],
    });
  });

  const options = {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        fontColor: '#555555',
        fontSize: 13,
        boxWidth: 23,
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      intersect: false,
      mode: 'index',
      bodySpacing: 6,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: 'rgba(155,155,155, 1)',
      borderWidth: 1,
      bodyFontColor: '#000',
      titleFontColor: '#000',
      callbacks: {
        label(tooltipItem, data) {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString('fr-FR')}`;
        },
        labelColor: (tooltipItem, data) => {
          const item = data.config.data.datasets[tooltipItem.datasetIndex];
          return {
            borderColor: item.borderColor,
            backgroundColor: item.dashed ? '#FFF' : item.borderColor,
          };
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
            stepSize: 2,
            displayFormats: {
              day: 'DD MMM',
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            reverse: props.reverse,
            callback(value) {
              if (Math.floor(value) === value) {
                return Number(value).toLocaleString('fr-FR');
              }
            },
          },
        },
      ],
    },
  };

  return (
    <div className="chart-js position-relative" style={{ height: '310px' }}>
      <Line data={data} options={options} height={310} />
    </div>
  );
};

LineChart.propTypes = {
  label: PropTypes.array,
  labels: PropTypes.array,
  values: PropTypes.array,
};

export default LineChart;
