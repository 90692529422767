import React from 'react';
import moment from 'moment';

export default (props) => {
  const {
    summary,
    addThousands,
    summary: { domains },
    getDiff,
    dayEnd,
    actualMonthErr,
  } = props;
  const summaryCloned = { ...summary, ...{} };
  delete summaryCloned.domains;
  return (
    <div className="summary-section px-4 py-5 print-page-break-before">
      <div className="tooltip2">
        <div className="position-relative">
          <span className="tooltiptext">
            Zestawienie danych miesięcznych zawierające porównanie najbardziej aktualnego miesiąca z wybranego zakresu czasu raportu do poprzedniego. Dane pochodzą z narzędzia SEMSTORM.
            <br />
            <br />
            Widoczność – szacunkowa miesięczna łączna liczba unikalnych fraz kluczowych w danym zakresie pozycji.
            <br />
            <br />
            Ruch – szacowany miesięczny ruch organiczny ze wszystkich fraz kluczowych, uzależniony od pozycji danej frazy i szacunkowego CTR odpowiadającemu miejscu w rankingu.
          </span>
        </div>
        <h5 className="mb-5 text-center">
          Miesięczna widoczność i szacowany ruch w porównaniu z konkurencją{' '}
          {actualMonthErr && dayEnd >= moment().startOf('month') && (
            <span style={{ fontSize: '1rem' }}>
              <br />
              (Dane z aktualnego miesiąca nie zostały pobrane)
            </span>
          )}
        </h5>
      </div>
      <table className="table-summary-competition table text-center table-hover mb-0">
        <thead>
          <tr>
            <th />
            {Object.keys(summaryCloned).map((val) => {
              let tooltipVal = '';
              switch (val) {
                case 'TOP 3':
                  tooltipVal = 'Widoczność fraz w TOP 3 – szacunkowa miesięczna liczba fraz, na które witryna pojawia się w wynikach wyszukiwania na pozycjach w przedziale od 1 do 3 miejsca. ';
                  break;
                case 'TOP 10':
                  tooltipVal = 'Widoczność fraz w TOP 10 – szacunkowa miesięczna liczba fraz, na które witryna pojawia się w wynikachwyszukiwania na pozycjach w przedziale od 1 do 10 miejsca.';
                  break;
                case 'TOP 20':
                  tooltipVal = 'Widoczność fraz w TOP 20 – szacunkowa miesięczna liczba fraz, na które witryna pojawia się w wynikachwyszukiwania na pozycjach w przedziale od 1 do 20 miejsca.';
                  break;
                case 'RUCH':
                  tooltipVal = 'Szacowany miesięczny ruch organiczny ze wszystkich fraz kluczowych.';
                  break;
                default:
                  tooltipVal = '';
              }
              return (
                <th className="tooltip2" key={val}>
                  <div className="position-relative">
                    <span className="tooltiptext">{tooltipVal}</span>
                  </div>
                  {val} <span className="quotation-mark" />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {domains.map((domain, domainIndex) => (
            <tr key={domain}>
              <td className="borderR text-left">{domain}</td>
              {Object.keys(summaryCloned).map((val, index) => {
                // Każde dwa miesjca w kolejności summaryCloned[TYP] zajmuje wynik dla 1 domeny
                // Wynik aktualny miesiąc oraz różnica do poprzedniego
                const valIndex = domainIndex * 2;
                const actualVal = <span className="text-actual">{addThousands(summaryCloned[val][valIndex])}</span>;
                const prevValStr = getDiff(summaryCloned[val][valIndex + 1], '', false, false);
                return (
                  <td key={`${val}${domainIndex}${index}`}>
                    {actualVal}
                    <br />
                    {prevValStr}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
