import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import user from './user';
import actualMonthData from './actualMonthData';
import reqCounter from './reqCounter';

const superApp = combineReducers({
  user,
  actualMonthData,
  reqCounter,
  session: sessionReducer,
});

export default superApp;
