import React, { Component } from 'react';
import Summary from './Summary';
import LineChart2 from '../../../components/LineChart/LineChart2';
import fetchReport from '../fetchReport';
import Error from '../../../components/Error/Error';
import shouldReload from '../shouldReload';
import moment from 'moment';

class MainChart extends Component {
	constructor(props) {
		super(props);
		const { dayStart, dayEnd, prevStart, prevEnd } = this.props;
		this.state = {
			dayStart,
			dayEnd,
			prevStart,
			prevEnd
		};
	}

	componentDidMount() {
		this.fetchDataforSummary();
		// this.fetchDataforChart();
	}

	componentDidUpdate(prevProps, prevState) {
		const newData = shouldReload(prevProps, prevState, this.props);
		if (newData) {
			this.setState(newData, () => {
				this.fetchDataforSummary();
				// this.fetchDataforChart();
			});
		}
	}

	fetchDataforSummary() {
		const { client } = this.props;
		const { data = [], dayStart, dayEnd, prevStart, prevEnd } = this.state;
		if (client._id) {
			this.setState({ summary: [] }, () => {
				fetchReport(client, dayStart, dayEnd, prevStart, prevEnd, 'summary-chart')
					.catch((err) =>
						this.setState({
							info: <div style={{ textAlign: 'center' }}><h4>Wykres dziennych kliknięć i wyświetleń</h4><p style={{color: 'red'}}>{err}</p><hr/></div>,
							loader: false
						})
					)
					.then((res) =>
						this.setState({
							...data,
							summary: res && res.summary
						})
					);
			});
		}
	}

	fetchDataforChart() {
		const { client } = this.props;
		const { summary = [], dayStart, dayEnd, prevStart, prevEnd } = this.state;
		if (client._id) {
			this.setState({ loader: true, data: [] }, () => {
				fetchReport(client, dayStart, dayEnd, prevStart, prevEnd, 'main-chart')
					.catch(() =>
						this.setState({
							info: 'Wystąpił błąd',
							loader: false
						})
					)
					.then((res) =>
						this.setState({
							...summary,
							data: res && res.report,
							loader: false
						})
					);
			});
		}
	}

	render() {
		const { styles, toComma, ctr, addThousands, getDiff, getColor, percentageDifference } = this.props;
		const { summary = [] } = this.state;
		const chartData = {
			arrDate: [],
			arrClicks: {
				values: [ [] ],
				label: [ 'Kliknięcia' ]
			},
			arrImpressions: {
				values: [ [] ],
				label: [ 'Wyświetlenia' ],
				hidden: true
			},
			prevArrClicks: {
				values: [ [] ],
				label: [ 'Kliknięcia (poprzedni okres)' ]
			},
			prevArrImpressions: {
				values: [ [] ],
				label: [ 'Wyświetlenia (poprzedni okres)' ],
				hidden: true
			}
		};
		Object.values(summary).map((el) => {
			chartData.arrDate.push(el.date);
			chartData.arrClicks.values[0].push(el.sum_clicks);
			chartData.arrImpressions.values[0].push(el.sum_impressions);
			chartData.prevArrClicks.values[0].push(el.prev_sum_clicks);
			chartData.prevArrImpressions.values[0].push(el.prev_sum_impressions);
			return chartData;
		});

		const objSummary = {
			sum_clicks: Object.values(summary).reduce((a = {}, b = {}) => a + b.sum_clicks, 0),
			sum_impressions: Object.values(summary).reduce((a = {}, b = {}) => a + b.sum_impressions, 0),
			prev_sum_clicks: Object.values(summary).reduce((a = {}, b = {}) => a + (b.prev_sum_clicks || 0), 0),
			prev_sum_impressions: Object.values(summary).reduce((a = {}, b = {}) => a + (b.prev_sum_impressions || 0), 0)
		};
		objSummary.avg_position =
			Object.values(summary).reduce((a = {}, b = {}) => a + b.avg_position * b.sum_impressions, 0) / objSummary.sum_impressions;
		objSummary.prev_avg_position =
			Object.values(summary).reduce((a = {}, b = {}) => a + (b.prev_avg_position || 0) * (b.prev_sum_impressions || 0), 0) /
			objSummary.prev_sum_impressions;
		return this.state.loader ? (
			<div className="lds-dual-ring" id="spinner" />
		) : summary.length ? (
			<div name="href2">
				<Summary
					format="html"
					styles={styles}
					toComma={toComma}
					ctr={ctr}
					addThousands={addThousands}
					summary={objSummary}
					getDiff={getDiff}
					percentageDifference={percentageDifference}
				/>
				<div className="px-4 py-5">
					<div className="tooltip2">
						<div className="position-relative">
							<span className="tooltiptext">
								Wykres dzienny kliknięć i wyświetleń w analizowanym okresie. Dane pochodzą z Google Search Console.
							</span>
						</div>
						<h5 className="mb-5 text-center">Wykres dziennych kliknięć i wyświetleń</h5>
					</div>
					<LineChart2
						getColor={getColor}
						labels={chartData.arrDate}
						values={[ chartData.arrClicks, chartData.arrImpressions, chartData.prevArrClicks, chartData.prevArrImpressions ]}
						redraw
						xAxes={[
							{
								ticks: {
									callback: function(value, index, values) {
										return index % 2 === 0
											? this.state.dayEnd.diff(moment(value), 'months') > 0 ? moment(value).format('DD') : moment(value).format('DD MMM')
											: '';
									}.bind(this)
								}
							}
						]}
					/>
				</div>
			</div>
		) : (
			<Error info={this.state.info} /> || null
		);
	}
}

export default MainChart;
