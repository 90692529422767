import React, { Component } from 'react';
import moment from 'moment';
import fetchReport from '../fetchReport';
import TableLandingPage from './TableLandingPage';
import TableKeywordsByDays from './TableKeywordsByDays';
import TableKeywords from './TableKeywords';
import TableKeywordsTop100 from './TableKeywordsTop100';
import Error from '../../../components/Error/Error';
import shouldReload from '../shouldReload';

class Tables extends Component {
  constructor(props) {
    super(props);
    const { dayStart, dayEnd, prevStart, prevEnd } = this.props;
    this.state = {
      dayStart,
      dayEnd,
      prevStart,
      prevEnd,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const newData = shouldReload(prevProps, prevState, this.props);
    if (newData) {
      this.setState(newData, () => {
        this.fetchData();
      });
    }
  }

  fetchData() {
    const { client } = this.props;
    const { dayStart, dayEnd, prevStart, prevEnd } = this.state;
    if (client._id) {
      this.setState({ reports: {}, loader: true });
      fetchReport(client, dayStart, dayEnd, prevStart, prevEnd, 'reports')
        .catch((err) =>
          this.setState({
            info: (
              <div style={{ textAlign: 'center' }}>
                <h4>Wykres dziennych pozycji monitorowanych fraz</h4>
                <p style={{ color: 'red' }}>{err}</p>
                <hr />
              </div>
            ),
            loader: false,
          })
        )
        .then((res) => {
          if (!res || res.error) {
            this.setState({
              info: (
                <div style={{ textAlign: 'center' }}>
                  <h4>Wykres dziennych pozycji monitorowanych fraz</h4>
                  <p style={{ color: 'red' }}>Wystąpił błąd</p>
                  <hr />
                </div>
              ),
              loader: false,
            });
          } else if (res.report) {
            this.setState({
              reports: res.report,
              loader: false,
            });
          }
        });
    }
  }

  renderError() {
    const { info } = this.state;
    if (info) {
      return (
        <div style={{ margin: 50 }}>
          <h6>{info}</h6>
        </div>
      );
    }
  }

  clickReducer(arr = []) {
    return arr.reduce((final, el) => (el.sum_clicks ? [...final, el] : final), []);
  }

  render() {
    const { reports = {} } = this.state;
    const { site = [], query = [], queryTop100 = [], queryDays = {} } = reports;
    const { dayStart, dayEnd, getColor, getDiff, addThousands, toComma, ctr, percentageDifference } = this.props;
    const formattedDayStart = moment(dayStart).format('YYYY-MM-DD');
    const formattedDayEnd = moment(dayEnd).format('YYYY-MM-DD');
    return this.state.loader ? (
      <div className="lds-dual-ring" id="spinner" />
    ) : Object.values(reports).length ? (
      <div>
        <TableKeywordsByDays queryDays={queryDays} dayStart={formattedDayStart} dayEnd={formattedDayEnd} getColor={getColor} addThousands={addThousands} />
        <TableKeywords
          query={query}
          dayStart={formattedDayStart}
          dayEnd={formattedDayEnd}
          getDiff={getDiff}
          addThousands={addThousands}
          ctr={ctr}
          toComma={toComma}
          percentageDifference={percentageDifference}
        />
        <TableKeywordsTop100 queryTop100={this.clickReducer(queryTop100)} dayStart={formattedDayStart} dayEnd={formattedDayEnd} getDiff={getDiff} addThousands={addThousands} />
        <TableLandingPage site={this.clickReducer(site)} getDiff={getDiff} />
      </div>
    ) : (
      <Error info={this.state.info} /> || null
    );
  }
}

export default Tables;
