import { connect } from 'react-redux';
import Login from './Login';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  onLogin: (user) => ({
    type: 'SET_USER',
    user,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
