export default (prevProps, prevState, props) => {
  const { formatDate, dayStart, dayEnd, focusedInput, prevStart, prevEnd, prevFocusedInput } = props;
  if ((prevProps.focusedInput !== focusedInput || prevProps.prevFocusedInput !== prevFocusedInput) && (!focusedInput && !prevFocusedInput)
    || (prevProps.client._id !== props.client._id)) {
    if ((formatDate(prevState.dayStart) !== formatDate(dayStart))
      || (formatDate(prevState.dayEnd) !== formatDate(dayEnd))
      || (formatDate(prevState.prevStart) !== formatDate(prevStart))
      || (formatDate(prevState.prevEnd) !== formatDate(prevEnd))
      || (prevProps.client._id !== props.client._id)
    ) {
      return ({dayStart, dayEnd, prevStart, prevEnd});
    }
  }
}
