import React from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';
import MainChart from './Charts/MainChart';
import GroupChart from './Charts/GroupChart';
import Tables from './Tables/Tables';
import CompetitionClientsChart from './Charts/CompetitionClientsChart';
import SummaryChartDateGrouped from './Charts/SummaryChartDateGrouped';

const SeoReportHtml = (props) => {
  const { prop } = props;
  return (
    <div className="components-content-holder">
      <Menu />
      <MainChart
        client={prop.client}
        styles={prop.styles}
        ctr={prop.ctr}
        toComma={prop.toComma}
        addThousands={prop.addThousands}
        dayStart={prop.dayStart}
        dayEnd={prop.dayEnd}
        focusedInput={prop.focusedInput}
        prevStart={prop.prevStart}
        prevEnd={prop.prevEnd}
        prevFocusedInput={prop.prevFocusedInput}
        formatDate={prop.formatDate}
        getDiff={prop.getDiff}
        getColor={prop.getColor}
        percentageDifference={prop.percentageDifference}
      />
      <SummaryChartDateGrouped
        client={prop.client}
        styles={prop.styles}
        toComma={prop.toComma}
        addThousands={prop.addThousands}
        dayEnd={prop.dayEnd}
        focusedInput={prop.focusedInput}
        formatDate={prop.formatDate}
        getDiff={prop.getDiff}
        getColor={prop.getColor}
        percentageDifference={prop.percentageDifference}
      />
      <GroupChart
        client={prop.client}
        average={prop.average}
        dayStart={prop.dayStart}
        dayEnd={prop.dayEnd}
        addThousands={prop.addThousands}
        focusedInput={prop.focusedInput}
        prevStart={prop.prevStart}
        prevEnd={prop.prevEnd}
        prevFocusedInput={prop.prevFocusedInput}
        formatDate={prop.formatDate}
        getDiff={prop.getDiff}
        getColor={prop.getColor}
        percentageDifference={prop.percentageDifference}
      />
      <CompetitionClientsChart
        client={prop.client}
        dayStart={prop.dayStart}
        dayEnd={prop.dayEnd}
        focusedInput={prop.focusedInput}
        prevFocusedInput={prop.prevFocusedInput}
        formatDate={prop.formatDate}
        addThousands={prop.addThousands}
        getColor={prop.getColor}
        getDiff={prop.getDiff}
      />
      <Tables
        client={prop.client}
        dayStart={prop.dayStart}
        dayEnd={prop.dayEnd}
        focusedInput={prop.focusedInput}
        prevStart={prop.prevStart}
        prevEnd={prop.prevEnd}
        prevFocusedInput={prop.prevFocusedInput}
        formatDate={prop.formatDate}
        getColor={prop.getColor}
        getDiff={prop.getDiff}
        addThousands={prop.addThousands}
        ctr={prop.ctr}
        toComma={prop.toComma}
        percentageDifference={prop.percentageDifference}
      />
    </div>
  );
};

SeoReportHtml.propTypes = {
  client: PropTypes.object,
  data: PropTypes.object,
  summary: PropTypes.func,
  average: PropTypes.func,
  addThousands: PropTypes.func,
};

export default SeoReportHtml;
