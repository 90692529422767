const roundNumber = (value, decimal) => {
  let number = Number(value.toFixed(decimal));
  if (number === 0.0) { number = 0; }
  return number;
};

const ctr = (clicks, impressions) => {
  const value = impressions > 0 ? (clicks / impressions * 100) : 0;
  return roundNumber(value,1);
};

const returnRows = (data, firstCol) => {
  const rows = [];
  data[0] && data.forEach((row) => {
    const { query, sum_impressions = 0, prev_sum_impressions = 0, sum_clicks = 0, prev_sum_clicks = 0, avg_position = 0, prev_avg_position = 0 } = row;
    const obj = {
      firstCol: row[firstCol] || query,
      impressions: sum_impressions,
      prev_impressions: roundNumber(sum_impressions - prev_sum_impressions, 0),
      clicks: sum_clicks,
      prev_clicks: roundNumber(sum_clicks - prev_sum_clicks, 1),
      ctr: roundNumber(ctr(sum_clicks, sum_impressions), 1),
      prev_ctr: roundNumber((ctr(sum_clicks, sum_impressions) - ctr(prev_sum_clicks, prev_sum_impressions)), 1),
      avg_position: avg_position,
      prev_avg_position: (prev_avg_position === 0 ? -1 : 1) * roundNumber(avg_position - prev_avg_position, 1),
    };
    rows.push(obj);
  });
  return rows;
};


const returnRowsCSV = (data) => {
  return data.map((el) => {
    Object.entries(el).forEach((el2) => {
        typeof el2[1] === 'number' ? el[el2[0]] = el2[1].toString().replace('.', ',') : el[el2[0]] = el2[1];
    });
    return el;
  });
};

module.exports = {
  returnRows,
  returnRowsCSV,
};
