import React, { Component } from 'react';
import LineChart2 from '../../../components/LineChart/LineChart2';
import fetchReport from '../fetchReport';
import Error from '../../../components/Error/Error';
import shouldReload from '../shouldReload';
const moment = require('moment');

class SummaryChartDateGrouped extends Component {
  constructor(props) {
    super(props);
    const { dayEnd } = this.props;
    this.state = {
      dayEnd,
    };
    this.groupDataByMonth = this.groupDataByMonth.bind(this);
  }

  componentDidMount() {
    this.fetchSummaryData();
  }

  componentDidUpdate(prevProps, prevState) {
    const newData = shouldReload(prevProps, prevState, this.props);
    if (newData) {
      this.setState(newData, () => {
        this.fetchSummaryData();
      });
    }
  }

  groupDataByMonth(data) {
    const groupedObj = {};
    for (const obj of data) {
      const date = moment(obj.date).format('YYYY-MM');
      if (!groupedObj[date]) {
        groupedObj[date] = {
          sum_clicks: 0,
          sum_impressions: 0,
        };
      }
      groupedObj[date].sum_clicks = groupedObj[date].sum_clicks + obj.sum_clicks;
      groupedObj[date].sum_impressions = groupedObj[date].sum_impressions + obj.sum_impressions;
    }
    return groupedObj;
  }

  fetchSummaryData() {
    const { client } = this.props;
    const { dayEnd } = this.state;
    if (client._id) {
      this.setState({ data: [], dataPrev: [] }, () => {
        const dateStart = moment(dayEnd).subtract(11, 'months').startOf('month');
        const dateEnd = moment(dayEnd).endOf('month');
        const prevDateStart = moment(dateStart).subtract(1, 'year');
        const prevDateEnd = moment(dateEnd).subtract(1, 'year');
        Promise.all([fetchReport(client, dateStart, dateEnd, null, null, 'main-chart'), fetchReport(client, prevDateStart, prevDateEnd, null, null, 'main-chart')])
          .then(([data, dataPrev]) => {
            if (data && data.report && dataPrev && dataPrev.report) {
              const dataGrouped = this.groupDataByMonth(data.report);
              const dataPrevGrouped = this.groupDataByMonth(dataPrev.report);
              this.setState(({ data, dataPrev }) => ({
                data: dataGrouped,
                dataPrev: dataPrevGrouped,
              }));
            } else {
              this.setState({
                info: (
                  <div style={{ textAlign: 'center' }}>
                    <h4>Wykres miesięcznej liczby kliknięć i wyświetleń rok do roku</h4>
                    <p style={{ color: 'red' }}>Wystąpił błąd</p>
                    <hr />
                  </div>
                ),
                loader: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              info: (
                <div style={{ textAlign: 'center' }}>
                  <h4>Wykres miesięcznej liczby kliknięć i wyświetleń rok do roku</h4>
                  <p style={{ color: 'red' }}>{err}</p>
                  <hr />
                </div>
              ),
              loader: false,
            });
          });
      });
    }
  }

  render() {
    const { styles, toComma, addThousands, getDiff, getColor, percentageDifference } = this.props;
    const { data = {}, dataPrev = {} } = this.state;
    const xAxes = [
      {
        type: 'time',
        time: {
          unit: 'month',
          stepSize: 1,
          displayFormats: {
            day: 'YYYY MMM',
          },
        },
      },
    ];
    const chartData = {
      arrDate: [],
      arrClicks: {
        values: [[]],
        label: ['Kliknięcia'],
      },
      arrImpressions: {
        values: [[]],
        label: ['Wyświetlenia'],
        hidden: true,
      },
      prevArrClicks: {
        values: [[]],
        label: ['Kliknięcia (poprzedni okres)'],
      },
      prevArrImpressions: {
        values: [[]],
        label: ['Wyświetlenia (poprzedni okres)'],
        hidden: true,
      },
    };
    Object.keys(data).forEach((item) => {
      const dateN = moment(item).subtract(1, 'year').format('YYYY-MM');
      chartData.arrDate.push(item);
      chartData.arrClicks.values[0].push(data[item].sum_clicks);
      chartData.arrImpressions.values[0].push(data[item].sum_impressions);
      if (dataPrev[dateN]) {
        chartData.prevArrClicks.values[0].push(dataPrev[dateN].sum_clicks);
        chartData.prevArrImpressions.values[0].push(dataPrev[dateN].sum_impressions);
      } else {
        chartData.prevArrClicks.values[0].push('');
        chartData.prevArrImpressions.values[0].push('');
      }
    });
    return this.state.loader ? (
      <div className="lds-dual-ring" id="spinner" />
    ) : Object.keys(data).length ? (
      <div name="href2">
        <div className="px-4 py-5 print-page-break-before">
          <div className="tooltip2">
            <div className="position-relative">
              <span className="tooltiptext">
                Wykres miesięcznej liczby kliknięć i wyświetleń w ostatnich 12 miesiącach licząc od najbardziej aktualnego miesiąca z wybranego zakresu czasu w porównaniu do poprzednich 12 miesięcy.
                Dane pochodzą z Google Search Console.
              </span>
            </div>
            <h5 className="mb-5 text-center">Wykres miesięcznej liczby kliknięć i wyświetleń rok do roku</h5>
          </div>
          <LineChart2
            getColor={getColor}
            labels={chartData.arrDate}
            values={[chartData.arrClicks, chartData.arrImpressions, chartData.prevArrClicks, chartData.prevArrImpressions]}
            redraw
            xAxes={xAxes}
          />
        </div>
      </div>
    ) : (
      <Error info={this.state.info} /> || null
    );
  }
}

export default SummaryChartDateGrouped;
