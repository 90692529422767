import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SeoReportHtml from './SeoReportHtml';
import moment from 'moment';

class SeoReport extends Component {
  summary(arr) {
    return arr.length ? (arr.reduce((a = 0, b = 0) => a + b) / 1000).toFixed(1) : 0;
  }

  ctr(clicks, impressions) {
    const value = impressions > 0 ? (clicks / impressions) * 100 : 0;
    return Number(value.toFixed(1));
  }

  average(arr) {
    return arr.length ? (arr.reduce((a = 0, b = 0) => a + b) / arr.length).toFixed(1) : 0;
  }

  toComma(num) {
    return num.toString().replace('.', ',');
  }

  addThousands(num = 0) {
    return Number(num).toLocaleString('fr-FR');
  }

  percentageDifference(oldVal, newVal) {
    const diff = newVal - oldVal;
    return `${((newVal / (newVal - diff) - 1) * 100).toFixed(1)}%`;
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getColor(lineIndex = 0, colorIndex = 0, styled = true) {
    const colors = [
      ['#fe0000', '#ae0001'],
      ['#5e5b62', '#000000'],
      ['#d1d4d9', '#98a1aa'],
      ['#943435', '#5e0000'],
      ['#ffb1b1', '#a57374'],
      ['#fd5200', '#ab3700'],
      ['#2cb500', '#1e7a00'],
      ['#0080db', '#00528d'],
      ['#6400e9', '#35007a'],
    ];
    const color = colorIndex % 2 === 0 ? colors[lineIndex][0] : colors[lineIndex][1];
    if (styled) {
      return {
        backgroundColor: colorIndex > 1 ? 'transparent' : color,
        borderColor: color,
        borderStyle: colorIndex > 1 ? 'dashed' : 'solid',
      };
    } else {
      return color;
    }
  }

  getDiff(val, mark = '', reversColors = false, bracket = true) {
    const colorClass = ['text-green', 'text-red'];
    const prevVal = this.addThousands(val);
    let prevValStr = (
      <span className={reversColors ? colorClass[1] : colorClass[0]}>
        +{prevVal}
        {mark}
      </span>
    );
    if (prevVal.toString().charAt(0) === '-') {
      prevValStr = (
        <span className={reversColors ? colorClass[0] : colorClass[1]}>
          {prevVal}
          {mark}
        </span>
      );
    }
    if (['0', '-0'].includes(prevVal.toString())) {
      prevValStr = (
        <span>
          0
          {mark}
        </span>
      );
    }
    if (!bracket) {
      return <span className="text-prev">{prevValStr}</span>;
    }
    return <span className="text-prev">({prevValStr})</span>;
  }

  render() {
    const { styles = {}, format } = this.props;
    const { client } = this.props;
    const prop = {
      ...this.props,
      styles,
      summary: this.summary,
      ctr: this.ctr.bind(this),
      average: this.average.bind(this),
      toComma: this.toComma.bind(this),
      addThousands: this.addThousands.bind(this),
      percentageDifference: this.percentageDifference.bind(this),
      formatDate: this.formatDate.bind(this),
      getColor: this.getColor.bind(this),
      getDiff: this.getDiff.bind(this),
    };
    return format === 'html' && client ? <SeoReportHtml prop={prop} /> : null;
  }
}

SeoReport.propTypes = {
  client: PropTypes.object,
  styles: PropTypes.object,
  format: PropTypes.string,
};

export default SeoReport;
