import React, { Component } from 'react';
import LineChart from '../../../components/LineChart/LineChart3';
import SummaryChartGroup from './SummaryChartGroup';
import fetchReport from '../fetchReport';
import Error from '../../../components/Error/Error';
import shouldReload from '../shouldReload';

class GroupChart extends Component {
	constructor(props) {
		super(props);
		const { dayStart, dayEnd, prevStart, prevEnd } = this.props;
		this.state = {
			dayStart,
			dayEnd,
			prevStart,
			prevEnd
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		const newData = shouldReload(prevProps, prevState, this.props);
		if (newData) {
			this.setState(newData, () => {
				this.fetchData();
			});
		}
	}

	fetchData() {
		const { client } = this.props;
		const { data = [], dayStart, dayEnd, prevStart, prevEnd } = this.state;
		if (client._id) {
			this.setState({ loader: true });
			fetchReport(client, dayStart, dayEnd, prevStart, prevEnd, 'group-chart')
				.then((res) =>
					this.setState({
						...data,
						data: res && res.report,
						loader: false
					})
				)
				.catch((err) => {
					this.setState({
						info: <div style={{ textAlign: 'center' }}><h4>Wykres dziennej widoczności fraz</h4><p style={{color: 'red'}}>{err}</p><hr/></div>,
						loader: false
					})
				});
		}
	}

	render() {
		const { average, addThousands, getDiff, getColor, percentageDifference } = this.props;
		const { data: {
			chartsCurrent: currentData = [],
			chartsPrevious: previousData = [],
		} = {} } = this.state;

		const chartGroup = {
			arrDate: [],
			gr1: {
				values: [ [] ],
				prevValues: [ [] ],
				label: [ 'średnia pozycja 1-3' ]
			},
			gr2: {
				values: [ [] ],
				prevValues: [ [] ],
				label: [ 'średnia pozycja 4-10' ]
			},
			gr3: {
				values: [ [] ],
				prevValues: [ [] ],
				label: [ 'średnia pozycja 11-20' ]
			},
			gr4: {
				values: [ [] ],
				prevValues: [ [] ],
				label: [ 'średnia pozycja 21+' ],
				hidden: true
			}
		};

		Object.values(currentData).map((el) => {
			chartGroup.arrDate.push(el.date);
			chartGroup.gr1.values[0].push(el.less_than_4);
			chartGroup.gr2.values[0].push(el.from_4_to_10);
			chartGroup.gr3.values[0].push(el.from_10_to_20);
			chartGroup.gr4.values[0].push(el.more_than_20);
			return chartGroup;
		});

		Object.values(previousData).map((el) => {
			chartGroup.gr1.prevValues[0].push(el.less_than_4);
			chartGroup.gr2.prevValues[0].push(el.from_4_to_10);
			chartGroup.gr3.prevValues[0].push(el.from_10_to_20);
			chartGroup.gr4.prevValues[0].push(el.more_than_20);
			return chartGroup;
		});

		if (this.state.loader) {
			return <div className="lds-dual-ring" id="spinner" />;
		}

		if (!currentData.length) {
			return <Error info={this.state.info} /> || null
		}

		return <div name="href3">
			<SummaryChartGroup
				gr1={(+average(chartGroup.gr1.values[0]) || 0).toFixed()}
				gr2={(+average(chartGroup.gr2.values[0]) || 0).toFixed()}
				gr3={(+average(chartGroup.gr3.values[0]) || 0).toFixed()}
				gr4={(+average(chartGroup.gr4.values[0]) || 0).toFixed()}
				prevGr1={(+average(chartGroup.gr1.prevValues[0]) || 0).toFixed()}
				prevGr2={(+average(chartGroup.gr2.prevValues[0]) || 0).toFixed()}
				prevGr3={(+average(chartGroup.gr3.prevValues[0]) || 0).toFixed()}
				prevGr4={(+average(chartGroup.gr4.prevValues[0]) || 0).toFixed()}
				addThousands={addThousands}
				getDiff={getDiff}
				percentageDifference={percentageDifference}
			/>
			<div className="px-4 py-5">
				<div className="tooltip2">
					<div className="position-relative">
					<span className="tooltiptext">
						Dzienna liczba fraz kluczowych na pozycjach w określonych przedziałach. Statystyki przeliczane na bazie danych z Google
						Search Console.
					</span>
					</div>
					<h5 className="mb-5 text-center">Wykres dziennej widoczności fraz</h5>
				</div>
				<LineChart
					labels={chartGroup.arrDate}
					values={[ chartGroup.gr1, chartGroup.gr2, chartGroup.gr3, chartGroup.gr4 ]}
					fill={false}
					redraw
					getColor={getColor}
				/>
			</div>
		</div>
	}
}

export default GroupChart;
