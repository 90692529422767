import React from 'react';

export default () => (
  <div className="container min-vh-100">
    <div className="row justify-content-center align-items-center min-vh-100">
      <div className="col-12 col-sm-10 col-lg-5">
        <h1>403</h1>
        <h6>Brak dostępu</h6>
        <a href="/">Przejdź do strony głównej</a>
      </div>
    </div>
  </div>
);