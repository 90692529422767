import React from 'react';
import NumberFormat from 'react-number-format';

export default (prop) => (
  <NumberFormat
    value={prop.value}
    displayType='text'
    thousandSeparator={' '}
    decimalSeparator=','
    allowedDecimalSeparators={[',', '.']}
    fixedDecimalScale
    decimalScale={prop.decimal || 0}
    isNumericString
  />
);
