import React from 'react';
import classes from './Input.module.css';

export default ({type, label, id, value, placeholder, onChange, onBlur}) => (
  <React.Fragment>
    <label htmlFor={id}>{label}</label>
    <input
      type={type}
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      className={classes.input}
    />
  </React.Fragment>
);
