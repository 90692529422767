import React from 'react';

export default (props) => {
	const { ctr, toComma, summary, addThousands, getDiff, percentageDifference } = props;
	return (
		<div className="summary-section px-4 py-5">
			<div className="tooltip2">
				<div className="position-relative">
					<span className="tooltiptext">Statystyki zbiorcze w analizowanym okresie. Dane pochodzą z Google Search Console.</span>
				</div>
				<h5 className="mb-5 text-center">Podsumowanie statystyk</h5>
			</div>
			<div className="table-responsive-md">
				<table className="table-legend-competition table summary-table">
					<thead>
						<tr>
							<th className="tooltip2">
								<div className="position-relative">
									<span className="tooltiptext">
										<b>Kliknięcia</b> – oznaczają liczbę kliknięć użytkowników w link prowadzący do Twojej witryny w wynikach wyszukiwania w
										wybranym okresie.
									</span>
								</div>
								KLIKNIĘCIA <span className="quotation-mark" />
							</th>
							<th className="tooltip2">
								<div className="position-relative">
									<span className="tooltiptext">
										<b>Wyświetlenia</b> – wskazują ile razy użytkownicy zobaczyli link do Twojej strony w wynikach wyszukiwania w wybranym
										okresie.
									</span>
								</div>
								WYŚWIETLENIA <span className="quotation-mark" />
							</th>
							<th className="tooltip2">
								<div className="position-relative">
									<span className="tooltiptext">
										<b>CTR</b> – (Click Through Rate) wskazuje odsetek wyświetleń, które doprowadziły do kliknięcia użytkownika w link do
										Twojej strony w wybranym okresie.
									</span>
								</div>
								CTR <span className="quotation-mark" />
							</th>
							<th className="tooltip2">
								<div className="position-relative">
									<span className="tooltiptext">
										<b>Średnia pozycja</b> – wskazuje średnią pozycję linków do Twojej strony w wynikach wyszukiwania biorąc pod uwagę
										wszystkie wyświetlenia w wybranym okresie.
									</span>
								</div>
								ŚREDNIA POZYCJA <span className="quotation-mark" />
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<span className="position-relative">
									<span className="sum">{addThousands(summary.sum_clicks)}</span>
									<span className="diff">
										<br />
										<br />
										{getDiff(summary.sum_clicks - summary.prev_sum_clicks, '', false, false)}
										<br />
										{getDiff(percentageDifference(summary.prev_sum_clicks, summary.sum_clicks).slice(0, -1), '%', false, true)}
									</span>
								</span>
							</td>
							<td>
								<span className="position-relative">
									<span className="sum">{addThousands(summary.sum_impressions)}</span>
									<span className="diff">
										<br />
										<br />
										{getDiff(summary.sum_impressions - summary.prev_sum_impressions, '', false, false)}
										<br />
										{getDiff(percentageDifference(summary.prev_sum_impressions, summary.sum_impressions).slice(0, -1), '%', false, true)}
									</span>
								</span>
							</td>
							<td>
								<span className="position-relative">
									<span className="sum">{toComma(ctr(summary.sum_clicks || 0, summary.sum_impressions || 0))}%</span>
									<span className="diff">
										<br />
										<br />
										{getDiff(
											Number(
												(ctr(summary.sum_clicks || 0, summary.sum_impressions || 0) -
													ctr(summary.prev_sum_clicks || 0, summary.prev_sum_impressions || 0)).toFixed(1)
											),
											'',
											false,
											false
										)}
									</span>
								</span>
							</td>
							<td>
								<span className="position-relative">
									<span className="sum">{addThousands(summary.avg_position.toFixed(1))}</span>
									<span className="diff">
										<br />
										<br />
										{getDiff(summary.prev_avg_position.toFixed(1) - summary.avg_position.toFixed(1), '', false, false)}
										<br />
										{getDiff(
											percentageDifference(summary.prev_avg_position.toFixed(), summary.avg_position.toFixed()).slice(0, -1) * -1,
											'%',
											false,
											true
										)}
									</span>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
