import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/pl';
import { Redirect } from 'react-router-dom';
import Headroom from 'react-headroom';
import { DateRangePicker } from 'react-dates';
import SeoReport from '../SeoReport/SeoReport';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '..';

class Sheet extends Component {
  constructor(props) {
    super(props);
    this.dates = {
      dayStart: moment().subtract(31, 'days'),
      dayEnd: moment().subtract(3, 'days'),
      prevStart: moment().subtract(59, 'days'),
      prevEnd: moment().subtract(31, 'days'),
    };
    let { hash = null } = this.props.match.params;
    if (hash) {
      const [urlHash, start, end, pStart, pEnd] = hash.split('&');
      hash = urlHash;
      this.dates = {
        dayStart: start && moment(start).isValid() ? moment(start) : this.dates.dayStart,
        dayEnd: end && moment(end).isValid() ? moment(end) : this.dates.dayEnd,
        prevStart: pStart && moment(pStart).isValid() ? moment(pStart) : this.dates.prevStart,
        prevEnd: pEnd && moment(pEnd).isValid() ? moment(pEnd) : this.dates.prevEnd,
      };
    }
    this.state = {
      hash,
      loader: true,
      dayStart: this.dates.dayStart,
      dayEnd: this.dates.dayEnd,
      prevStart: this.dates.prevStart,
      prevEnd: this.dates.prevEnd,
      redirect: false,
    };
    this.changeDate = this.changeDate.bind(this);
    this.changeFocus = this.changeFocus.bind(this);
    this.changePrevDate = this.changePrevDate.bind(this);
    this.changePrevFocus = this.changePrevFocus.bind(this);
  }

  componentDidMount() {
    const { hash, dayStart, dayEnd } = this.state;
    if (hash) {
      this.fetchClientData(hash);
    } else {
      this.fetchClientData(null, () => this.setPrevDate(dayStart, dayEnd));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevState !== JSON.stringify(this.state))) {
      const { client = {}, dayStart, dayEnd } = this.state;
      let title = 'Raport SEO';
      if (client.website) {
        title += ` - ${client.website} (${moment(dayStart).format('YYYY-MM-DD')} - ${moment(dayEnd).format('YYYY-MM-DD')})`;
      }
      document.title = title;
    }
  }

  fetchClientData(hash, cb) {
    const { clientId } = this.props.user || {};
    if (hash || clientId) {
      fetch(`${API_SERVER}/api/client-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          hash,
          clientId,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response) {
            this.setState({
              info: 'Błąd połączenia z bazą',
              loader: false,
            });
          } else if (response.error) {
            this.setState({
              info: response.error,
              loader: false,
              redirect: '/403',
            });
          } else {
            const clientObj = response.success.filter((item) => item.hash === hash)[0];
            this.setState(
              {
                clients: response.success,
                client: clientObj ? clientObj : response.success[0],
                loader: false,
              },
              cb
            );
          }
        })
        .catch((error) => {
          console.error('Error: 46', error);
          this.setState({
            info: 'Błąd',
            loader: false,
          });
        });
    } else {
      this.setState({ redirect: '/' });
    }
  }

  changeDate(start, end) {
    if (start !== null && end !== null) {
      this.setPrevDate(start, end);
    }
  }

  changePrevDate(prevStart, prevEnd) {
    if (prevStart !== null && prevEnd !== null) {
      this.setState(
        {
          prevStart,
          prevEnd,
        },
        () => this.changeUrl(null, null, prevStart, prevEnd)
      );
    }
  }

  changeFocus(focusedInput) {
    this.setState({ focusedInput });
  }

  changePrevFocus(prevFocusedInput) {
    this.setState({ prevFocusedInput });
  }

  setPrevDate(start, end) {
    const diff = moment(end).add(1, 'days').diff(start, 'days');
    const prevStart = moment(start).subtract(diff, 'days');
    const prevEnd = moment(start).subtract(1, 'days');
    const dayStart = start;
    const dayEnd = end;
    this.setState({ diff, prevStart, prevEnd, dayStart, dayEnd }, () => this.changeUrl(null, null, prevStart, prevEnd));
  }

  changeUrl(startN = null, endN = null, prevStartN = null, prevEndN = null) {
    const { hash, dayStart, dayEnd, prevStart, prevEnd } = this.state;
    if (hash) {
      this.props.history.push(
        `${hash}&${moment(startN || dayStart).format('YYYY-MM-DD')}&${moment(endN || dayEnd).format('YYYY-MM-DD')}&${moment(prevStartN || prevStart).format('YYYY-MM-DD')}&${moment(
          prevEndN || prevEnd
        ).format('YYYY-MM-DD')}`
      );
    }
  }

  renderError() {
    const { info } = this.state;
    if (info) {
      return (
        <div style={{ margin: 50 }}>
          <h4>{info}</h4>
          <a href="/">Przejdź do strony głównej</a>
        </div>
      );
    }
  }

  renderSelect() {
    const { clients = [], client = {} } = this.state;
    return (
      <>
        <label style={{ fontSize: 11 }} className="d-block d-lg-inline-block text-center text-lg-left">
          Zmień raport{' '}
        </label>
        <select
          value={client.hash}
          onChange={(e) => {
            const newClient = clients.filter((el) => el.hash === e.target.value)[0] || {};
            this.setState(
              {
                hash: e.target.value,
                client: newClient,
              },
              () => this.changeUrl()
            );
          }}
        >
          {clients.map((el, key) => (
            <option key={key} value={el.hash}>
              {el.website}
            </option>
          ))}
        </select>
      </>
    );
  }

  render() {
    const { clients = [], client = {}, dayStart, dayEnd, prevStart = null, prevEnd = null, focusedInput, prevFocusedInput, redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <main className={`container-fluid px-3 ${this.props.reqCounter ? 'noEvents' : ''}`}>
        {this.state.loader ? <div className="lds-dual-ring d-print-none" id="spinner" /> : this.renderError()}
        <div className="dots-container d-print-none">
          <h6>Trwa pobieranie danych...</h6>
          <div className="dots" />
        </div>
        <div className="row no-gutters">
          <div className="col-12 content-holder">
            {client.company ? (
              <div className="content-header">
                <div className="text-center mb-3">
                  <h6 className="d-print-none">RAPORT&nbsp;SEO</h6>
                  <h1>{client.website}</h1>
                </div>
                <div className="d-none d-print-block">
                  <p className="mb-1">
                    <span>
                      <svg className="DateRangePickerInput_calendarIcon_svg DateRangePickerInput_calendarIcon_svg_1" focusable="false" viewBox="0 0 1393.1 1500">
                        <path d="m107 1393h241v-241h-241zm295 0h268v-241h-268zm-295-295h241v-268h-241zm295 0h268v-268h-268zm-295-321h241v-241h-241zm616 616h268v-241h-268zm-321-616h268v-241h-268zm643 616h241v-241h-241zm-322-295h268v-268h-268zm-294-723v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm616 723h241v-268h-241zm-322-321h268v-241h-268zm322 0h241v-241h-241zm27-402v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm321-54v1072c0 29-11 54-32 75s-46 32-75 32h-1179c-29 0-54-11-75-32s-32-46-32-75v-1072c0-29 11-54 32-75s46-32 75-32h107v-80c0-37 13-68 40-95s57-39 94-39h54c37 0 68 13 95 39 26 26 39 58 39 95v80h321v-80c0-37 13-69 40-95 26-26 57-39 94-39h54c37 0 68 13 94 39s40 58 40 95v80h107c29 0 54 11 75 32s32 46 32 75z" />
                      </svg>
                      obecny okres:
                    </span>
                    {`${dayStart.format('DD.MM.YYYY')} - ${dayEnd.format('DD.MM.YYYY')}`}
                  </p>
                  <p className="mb-0">
                    <span>
                      <svg className="DateRangePickerInput_calendarIcon_svg DateRangePickerInput_calendarIcon_svg_1" focusable="false" viewBox="0 0 1393.1 1500">
                        <path d="m107 1393h241v-241h-241zm295 0h268v-241h-268zm-295-295h241v-268h-241zm295 0h268v-268h-268zm-295-321h241v-241h-241zm616 616h268v-241h-268zm-321-616h268v-241h-268zm643 616h241v-241h-241zm-322-295h268v-268h-268zm-294-723v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm616 723h241v-268h-241zm-322-321h268v-241h-268zm322 0h241v-241h-241zm27-402v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm321-54v1072c0 29-11 54-32 75s-46 32-75 32h-1179c-29 0-54-11-75-32s-32-46-32-75v-1072c0-29 11-54 32-75s46-32 75-32h107v-80c0-37 13-68 40-95s57-39 94-39h54c37 0 68 13 95 39 26 26 39 58 39 95v80h321v-80c0-37 13-69 40-95 26-26 57-39 94-39h54c37 0 68 13 94 39s40 58 40 95v80h107c29 0 54 11 75 32s32 46 32 75z" />
                      </svg>
                      poprzedni okres:
                    </span>
                    {`${prevStart.format('DD.MM.YYYY')} - ${prevEnd.format('DD.MM.YYYY')}`}
                  </p>
                </div>
                <Headroom pinStart={120} downTolerance={0} upTolerance={120} className="d-print-none">
                  <div className="container-fluid">
                    <div className="content-filters row align-items-center justify-content-between py-1">
                      <div className="col-12 col-sm-4 mt-2 mt-sm-0 d-flex align-items-center justify-content-center justify-content-lg-start flex-column flex-lg-row">
                        <span className="d-block d-lg-inline-block">obecny okres</span>
                        <DateRangePicker
                          startDate={dayStart}
                          startDateId="dayStart"
                          endDate={dayEnd}
                          endDateId="dayEnd"
                          onDatesChange={({ startDate, endDate }) => this.changeDate(startDate, startDate >= endDate ? startDate : endDate)}
                          focusedInput={focusedInput}
                          onFocusChange={(focus) => this.changeFocus(focus)}
                          showDefaultInputIcon
                          noBorder
                          minimumNights={0}
                          initialVisibleMonth={() => (dayStart && focusedInput === 'startDate' ? dayStart : dayEnd || moment().subtract(1, 'month'))}
                          isOutsideRange={(date) => date >= moment().subtract(3, 'days')}
                          small
                        />
                      </div>
                      <div className="col-12 col-sm-4 text-lg-center mt-2 mt-sm-0 d-flex align-items-center justify-content-center flex-column flex-lg-row">
                        <span className="d-block d-lg-inline-block">poprzedni okres</span>
                        <DateRangePicker
                          startDate={prevStart}
                          startDateId="prevStart"
                          endDate={prevEnd}
                          endDateId="prevEnd"
                          onDatesChange={({ startDate, endDate }) => this.changePrevDate(startDate, startDate >= endDate ? startDate : endDate)}
                          focusedInput={prevFocusedInput}
                          onFocusChange={(focus) => this.changePrevFocus(focus)}
                          showDefaultInputIcon
                          noBorder
                          minimumNights={0}
                          initialVisibleMonth={() => (prevStart && prevFocusedInput === 'startDate' ? prevStart : prevEnd || moment().subtract(1, 'month'))}
                          isOutsideRange={(date) => date >= moment().subtract(3, 'days')}
                          small
                        />
                      </div>
                      <div className="col-12 col-sm-4 mt-2 mt-sm-0 d-flex align-items-center justify-content-center justify-content-lg-end flex-column flex-lg-row">
                        {clients.length > 1 ? this.renderSelect() : null}
                      </div>
                    </div>
                  </div>
                </Headroom>
              </div>
            ) : null}
            <hr className="d-none d-print-block" />
            {client.reports && client.reports.seo === '1' ? (
              <SeoReport client={client} dayStart={dayStart} dayEnd={dayEnd} focusedInput={focusedInput} prevStart={prevStart} prevEnd={prevEnd} prevFocusedInput={prevFocusedInput} format="html" />
            ) : null}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  reqCounter: state.reqCounter,
});

export default connect(mapStateToProps)(Sheet);
