const actualMonthData = (state = null, action) => {
  switch (action.type) {
  case 'SET_ACTUAL_MONTH_DATA':
    return action.actualMonthData;
  default:
    return state;
  }
};

export default actualMonthData;
