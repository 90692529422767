import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/Table/Table';
import { returnRows, returnRowsCSV } from './common';
import Input from '../../../components/Input/Input';
import { CSVLink } from 'react-csv';

const TableLandingPage = ({ site = [], dayStart = '', dayEnd = '' }) => {
  const firstCol = 'site';
  const firstColPl = 'URL';
  const [searchText, setSearchText] = useState('');

  const onChangeHandler = (event) => {
    setSearchText(event.target.value);
  };
  if (searchText) {
    site = site.filter((el) => el.site && el.site.includes(searchText));
  }

  const rows = returnRows(site, firstCol);
  const rowsCSV = returnRowsCSV(JSON.parse(JSON.stringify(rows)));

  return (
    <div className="d-print-none px-4 pb-4">
      <div className="tooltip2">
        <div className="position-relative">
          <span className="tooltiptext">
            Podstrony witryny, które w badanym okresie zanotowały co najmniej jedno kliknięcie, w porównaniu z poprzednim okresem. Dane pochodzą z Google Search Console.
          </span>
        </div>
        <h5 className="mb-5 text-center">Wszystkie podstrony generujące ruch</h5>
      </div>
      <Input {...{ type: 'text', placeholder: 'filtruj...', onChange: onChangeHandler }} />
      <Table rows={rows} firstColPl={firstColPl} />
      <div className="text-right mt-2">
        <CSVLink data={rowsCSV} filename={`Raport SEO - CubeGroup - Wszystkie podstrony generujące ruch od ${dayStart} do ${dayEnd}.csv`} separator=";">
          Pobierz dane w formacie csv
        </CSVLink>
      </div>
    </div>
  );
};

TableLandingPage.propTypes = {
  site: PropTypes.array,
  ctr: PropTypes.func,
};

export default TableLandingPage;
