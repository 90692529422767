import React from 'react';
import './Header.css';
import logo from '../../assets/img/logotyp_cube_group.jpg';
import Button from '../Button/Button';
import Logout from '../Logout/Logout';
import { connect } from 'react-redux';

const Header = props => (
  <header className={`header ${props.reqCounter ? 'noEvents' : ''}`}>
    <div>
      <img src={logo} alt="logo CubeGroup" />
      <h5>Raporty</h5>
    </div>
    <div className="d-print-none">
      <Logout />
      { window.location.pathname.indexOf('raport') > -1 && <Button text="Drukuj lub Zapisz" onClick={() => window.print()} /> }
    </div>
  </header>
);

const mapStateToProps = (state) => ({
  reqCounter: state.reqCounter,
});

export default connect(mapStateToProps)(Header);
