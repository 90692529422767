import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Table from '../../../components/Table/Table';
import { returnRows, returnRowsCSV } from './common';
import { renderDifference, renderNumber } from '../../../components/NumberFormat/render';

const TableKeywords = ({ query = [], dayStart = '', dayEnd = '', getDiff, addThousands, toComma, ctr, percentageDifference }) => {
	const firstCol = 'query';
	const firstColPl = 'Fraza';
	const rows = returnRows(query, firstCol);
	const _rows = JSON.parse(JSON.stringify(rows));
	const rowsCSV = returnRowsCSV(_rows);
	const summary = _rows.reduce((a, b) => {
		const impressions = parseFloat(a.impressions || 0) + parseFloat(b.impressions);
		const prev_impressions = parseFloat(a.prev_impressions || 0) + parseFloat(b.prev_impressions);
		const prev_impressions_diff = parseFloat(b.impressions) - parseFloat(b.prev_impressions);
		const avg_position_sum =
			(a.avg_position || 0) + parseFloat((b.avg_position || 0).toString().replace(',', '.')) * parseFloat(b.impressions);
		const avg_position_prev_sum =
			(a.prev_avg_position || 0) +
			(parseFloat((b.avg_position || 0).toString().replace(',', '.')) -
				parseFloat((b.prev_avg_position || 0).toString().replace(',', '.'))) *
				prev_impressions_diff;
		return {
			clicks: parseFloat(a.clicks || 0) + parseFloat(b.clicks),
			prev_clicks: parseFloat(a.prev_clicks || 0) + parseFloat(b.prev_clicks),
			impressions,
			prev_impressions,
			avg_position: avg_position_sum,
			prev_avg_position: avg_position_prev_sum,
			prev_impressions_diff_sum: (a.prev_impressions_diff_sum || 0) + prev_impressions_diff
		};
	}, 0);
	const prevClicksVal = summary.clicks - summary.prev_clicks;
	const prevImpressionsVal = summary.impressions - summary.prev_impressions;
	const ctrVal = ctr(summary.clicks || 0, summary.impressions || 0);
	const ctrPrevVal = ctr(prevClicksVal, prevImpressionsVal);
	const avgPosVal = summary.avg_position / summary.impressions;
	const avgPrevPosVal = summary.prev_avg_position / summary.prev_impressions_diff_sum;
	return (
		<div>
			<div className="summary-section px-4 py-5 print-page-break-before">
				<div className="tooltip2">
					<div className="position-relative">
						<span className="tooltiptext">
							Zbiorcze statystyki wszystkich monitorowanych fraz kluczowych w analizowanym okresie. Dane pochodzą z Google Search Console.
						</span>
					</div>
					<h5 className="mb-5 text-center">
						Podsumowanie statystyk monitorowanych fraz
					</h5>
				</div>
				<div className="table-responsive-md">
					<table className="table-legend-competition table mb-0 summary-table">
						<thead>
							<tr>
								<th className="tooltip2">
									<div className="position-relative">
										<span className="tooltiptext">
											Kliknięcia – oznaczają liczbę kliknięć użytkowników w link prowadzący do Twojej witryny w wynikach wyszukiwania w
											wybranym okresie.
										</span>
									</div>
									KLIKNIĘCIA <span className="quotation-mark" />
								</th>
								<th className="tooltip2">
									<div className="position-relative">
										<span className="tooltiptext">
											Kliknięcia – oznaczają liczbę kliknięć użytkowników w link prowadzący do Twojej witryny w wynikach wyszukiwania w
											wybranym okresie.
										</span>
									</div>{' '}
									WYŚWIETLENIA <span className="quotation-mark" />
								</th>
								<th className="tooltip2">
									<div className="position-relative">
										<span className="tooltiptext">
											Kliknięcia – oznaczają liczbę kliknięć użytkowników w link prowadzący do Twojej witryny w wynikach wyszukiwania w
											wybranym okresie.
										</span>
									</div>{' '}
									CTR <span className="quotation-mark" />
								</th>
								<th className="tooltip2">
									<div className="position-relative">
										<span className="tooltiptext">
											Średnia pozycja – wskazuje średnią pozycję linków do Twojej strony w wynikach wyszukiwania biorąc pod uwagę wszystkie
											wyświetlenia w wybranym okresie.
										</span>
									</div>{' '}
									ŚREDNIA POZYCJA <span className="quotation-mark" />
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<span className="position-relative">
										<span className="sum">{addThousands(Math.ceil(summary.clicks))}</span>
										<span className="diff">
											<br />
											<br />
											{getDiff(Math.ceil(summary.prev_clicks), '', false, false)}
											<br />
											{getDiff(
												percentageDifference(Math.ceil(summary.clicks - summary.prev_clicks), summary.clicks).slice(0, -1),
												'%',
												false
											)}
										</span>
									</span>
								</td>
								<td>
									<span className="position-relative">
										<span className="sum">{addThousands(Math.ceil(summary.impressions))}</span>
										<span className="diff">
											<br />
											<br />
											{getDiff(Math.ceil(summary.prev_impressions), '', false, false)}
											<br />
											{getDiff(
												percentageDifference(Math.ceil(summary.impressions - summary.prev_impressions), summary.impressions).slice(0, -1),
												'%',
												false
											)}
										</span>
									</span>
								</td>
								<td>
									<span className="position-relative">
										<span className="sum">{toComma(ctrVal)}%</span>
										<span className="diff">
											<br />
											<br />
											{getDiff((Number(ctrVal) - Number(ctrPrevVal)).toFixed(1), '', false, false)}
										</span>
									</span>
								</td>
								<td>
									<span className="position-relative">
										<span className="sum">{addThousands(avgPosVal.toFixed(1))}</span>
										<span className="diff">
											<br />
											<br />
											{getDiff(avgPrevPosVal.toFixed(1) - avgPosVal.toFixed(1), '', false, false)}
											<br />
											{getDiff(percentageDifference(avgPrevPosVal.toFixed(1), avgPosVal.toFixed(1)).slice(0, -1) * -1, '%', false, true)}
										</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<table className="d-none d-print-table table query-days phase">
				<thead>
					<tr>
						<th>Fraza</th>
						<th colSpan={2}>Wyświetlenia</th>
						<th colSpan={2}>Kliknięcia</th>
						<th colSpan={2}>CTR</th>
						<th colSpan={2}>Średnia pozycja</th>
					</tr>
				</thead>
				<tbody>
					{rows.map((row, key) => (
						<tr key={key}>
							<td>{row.firstCol}</td>
							<td>{renderNumber(row.impressions, 0)}</td>
							<td>{renderDifference(row.prev_impressions, 0)}</td>
							<td>{renderNumber(row.clicks, 0)}</td>
							<td>{renderDifference(row.prev_clicks, 0)}</td>
							<td>{renderNumber(row.ctr, 1, '%')}</td>
							<td>{renderDifference(row.prev_ctr, 1, '')}</td>
							<td>{renderNumber(row.avg_position, 1)}</td>
							<td>{renderDifference(row.prev_avg_position * -1, 1)}</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="d-print-none px-4 py-5">
				<Table rows={rows} firstColPl={firstColPl} />
				<div className="d-print-none text-right mt-2">
					<CSVLink
						data={rowsCSV}
						filename={`Raport SEO - CubeGroup - Podsumowanie statystyk monitorowanych fraz od ${dayStart} do ${dayEnd}.csv`}
						separator=";"
					>
						Pobierz dane w formacie csv
					</CSVLink>
				</div>
			</div>
		</div>
	);
};

TableKeywords.propTypes = {
	query: PropTypes.array,
	ctr: PropTypes.func,
	toComma: PropTypes.func
};

export default TableKeywords;
