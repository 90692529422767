import React from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import './Table.css';
import sort from "../../assets/img/sort-solid.svg";
import { renderDifference, renderNumber } from '../NumberFormat/render';


const Table = (props) => {
  const { firstColPl, rows, filterableFirstCol = false } = props;
  const columns = [
    {
      Header: () => (
        <span className="tbl-header">{firstColPl}<img src={sort} className="ico-sort" alt="" /></span>
      ),
      accessor: 'firstCol',
      style: { textAlign: 'left' },
      minWidth: 260,
      filterable: filterableFirstCol,
    },
    {
      Header: () => (
        <span className="tbl-header">Wyświetlenia<img src={sort} className="ico-sort" alt="" /></span>
      ),
      id: 'impressions',
      accessor: el => Number(el.impressions),
      Cell: prop => renderNumber(prop.value, 0),
    },
    {
      accessor: 'prev_impressions',
      Cell: prop => renderDifference(prop.value, 0),
      maxWidth: 65,
    },
    {
      Header: () => (
        <span className="tbl-header">Kliknięcia<img src={sort} className="ico-sort" alt="" /></span>
      ),
      id: 'clicks',
      accessor: el => Number(el.clicks),
      Cell: prop => renderNumber(prop.value, 0),
    },
    {
      accessor: 'prev_clicks',
      Cell: prop => renderDifference(prop.value ,0),
      maxWidth: 65,
    },
    {
      Header: () => (
        <span className="tbl-header">CTR<img src={sort} className="ico-sort" alt="" /></span>
      ),
      id: 'ctr',
      accessor: el => Number(el.ctr),
      Cell: prop => renderNumber(prop.value, 1, '%'),
    },
    {
      accessor: 'prev_ctr',
      Cell: prop => renderDifference(prop.value, 1, ''),
      maxWidth: 65,
    },
    {
      Header: () => (
        <span className="tbl-header">Średnia pozycja<img src={sort} className="ico-sort" alt="" /></span>
      ),
      id: 'avg_position',
      accessor: el => Number(el.avg_position),
      Cell: prop => renderNumber(prop.value, 1),
    },
    {
      accessor: 'prev_avg_position',
      Cell: prop => renderDifference(prop.value * -1 ,1),
      maxWidth: 65,
    },
  ];

  return (
    <ReactTable
      data={rows}
      columns={columns}
      showPagination
      defaultPageSize={25}
      minRows={1}
      resizable
      sortable
      className="-striped -highlight"
      style={{zIndex: 0, textAlign: 'right', maxHeight: '85vh'}}
      previousText={'Poprzednia'}
      nextText={'Następna'}
      pageText={'Strona'}
      rowsText={'wierszy'}
      ofText={'z'}
    />
  )
};

export default Table;
