import {composeWithDevTools} from "redux-devtools-extension";
import persistState from "redux-sessionstorage";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import reducer from "../../reducers";

const createPersistentStore = composeWithDevTools(
  persistState(),
  applyMiddleware(thunk),
)(createStore);

const store = createPersistentStore(reducer);
export default store;
