import React, { Component } from 'react';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

class Menu extends Component {
  componentDidMount() {
    Events.scrollEvent.register('begin', (to, element) => {
      // console.log("begin", arguments);
    });
    Events.scrollEvent.register('end', (to, element) => {
      // console.log("end", arguments);
    });
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollTo() {
    scroll.scrollTo(100);
  }

  render() {
    return (
      <div className="content-menu row no-gutters d-print-none">
        <Link className="col-6 col-sm-4 col-md-2" activeClass="active" to="href1" spy smooth offset={-70} duration={400}>
          Podsumowanie
        </Link>
        <Link className="col-6 col-sm-4 col-md-2" activeClass="active" to="href2" spy smooth offset={-70} duration={400}>
          Kliknięcia
        </Link>
        <Link className="col-6 col-sm-4 col-md-2" activeClass="active" to="href3" spy smooth offset={-70} duration={400}>
          Widoczność
        </Link>
        <Link className="col-6 col-sm-4 col-md-2" activeClass="active" to="href4" spy smooth offset={-70} duration={400}>
          Widoczność konkurencji
        </Link>
        <Link className="col-6 col-sm-4 col-md-2" activeClass="active" to="href5" spy smooth offset={-70} duration={400}>
          Monitoring
        </Link>
        <Link className="col-6 col-sm-4 col-md-2" activeClass="active" to="href6" spy smooth offset={-110} duration={400}>
          Kaloryczne
        </Link>
        <Element offset={0} duration={400} onClick={scroll.scrollToTop} className="btn btn-default btn-sm btn-back-top">
          ↑ Powrót
        </Element>
      </div>
    );
  }
}

export default Menu;
